<template>
  <success-page v-if="checkout_data != null" back_url="/" :back_label="$t('checkout.go_home_btn')">
    <template v-slot:title>
      {{ $t("checkout.success.title") }}
    </template>
    <template v-slot:body>
      {{ $t("checkout.success.bank_msg") }}
    </template>
  </success-page>
</template>

<script>
import {getCheckoutFromStorage, removeCheckoutFromStorage} from "@/includes/checkout";
import SuccessPage from "@/components/SuccessPage";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "CheckoutSuccess",
  components: {SuccessPage},
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("checkout.success.page_title"),
    })
  },
  data() {
    return {
      checkout_data: getCheckoutFromStorage(),
    }
  },
  methods: {
    checkCheckoutInStorage() {
      if (this.checkout_data !== null) {
        //Remove checkout from storage
        removeCheckoutFromStorage();
      } else {
        //Redirect home
        this.$router.replace("/");
      }
    }
  },
  created() {
    this.checkCheckoutInStorage();
  }
}
</script>

<style lang="scss" scoped>

</style>
